import { useFetchTrending } from "@organisms/coin/actions";
import { TrendingTokens, Coins, Container, CoinsPlaceholder } from "./trending";

export default function TrendingMobile(props) {
  const [slugs, loaded] = useFetchTrending();

  return (
    <Container
      direction="column"
      gap="small"
      pad={{ vertical: "4px", left: "16px" }}
      {...props}
    >
      {loaded ? <Coins coins={slugs} /> : <CoinsPlaceholder />}
      <TrendingTokens />
    </Container>
  );
}
