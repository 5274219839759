import { Box, Button, ThemeContext } from "grommet";
import Link from "next/link";
import { Menu as MenuIcon } from "grommet-icons";
import Logo from "./logo";

export default function TopBarMobile() {
  return (
    <Box
      direction="row"
      justify="between"
      pad={{ vertical: "small", left: "medium" }}
    >
      <Logo small />
      <Box direction="row" align="center">
        <ThemeContext.Extend value={{ global: { colors: { text: "white" } } }}>
          <Link href="/promote" passHref legacyBehavior>
            <Button label="Promote" primary size="small" />
          </Link>
        </ThemeContext.Extend>

        <Link href={{ hash: "menu" }} passHref legacyBehavior>
          <Button
            icon={<MenuIcon color="white" />}
            title="Menu"
            data-ga="headerSmall::click::menu"
          />
        </Link>
      </Box>
    </Box>
  );
}
