import { Box, Button } from "grommet";
import { Search as SearchIcon } from "grommet-icons";
import Link from "next/link";
import TopBarMobile from "./topBarMobile";
import Navigation from "./navigation";
import TrendingMobile from "./trendingMobile";

export default function HeaderMobile(props) {
  return (
    <Box {...props}>
      <TrendingMobile />
      <TopBarMobile />
      <Box border={{ side: "horizontal", color: "#2E364D" }} direction="row">
        <Navigation border={{ side: "right", color: "#2E364D" }} />
        <Link href={{ hash: "search" }} passHref legacyBehavior>
          <Button
            icon={<SearchIcon size="16px" />}
            title="Search"
            data-ga="headerSmall::click::search"
            alignSelf="center"
          />
        </Link>
      </Box>
    </Box>
  );
}
